import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  exports: [
  ],
  providers: [],
  declarations: [],
})
export class DirectivesModule {}
