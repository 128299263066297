export const APP_TABS = [
  {
    title: 'Lịch Ngày',
    url: '/tabs/date',
    icon: 'calendar-date',
  },
  {
    title: 'Lịch Tháng',
    url: '/tabs/month',
    icon: 'calendar-month',
  },
];
